// action - state management
import { SET_YOUTH_VALUE } from './actions';


//-----------------------|| ACCOUNT REDUCER ||-----------------------//

// Initial state for the youthValue reducer
const initialState = {};

// youthValue reducer
const youthReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_YOUTH_VALUE:
            return action.payload;
        default:
            return state;
    }
};


export default youthReducer;
