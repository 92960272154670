import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';


// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const DashboardCreate = Loadable(lazy(() => import('../views/create-sef/CreateSEF')));
const DashboardView = Loadable(lazy(() => import('../views/view-sef/ViewSEF')));
const DashboardExport = Loadable(lazy(() => import('../views/export-sef/ExportSEF')));

const BilledSEF = Loadable(lazy(() => import('../views/billed-sef/BilledSEF')));
const BilledSEFLogs = Loadable(lazy(() => import('../views/billed-sef/BilledSEFLogs')));
const ViewLogs = Loadable(lazy(() => import('../views/view-logs/ViewLogs')));


const DashboardExportAdmin = Loadable(lazy(() => import('../views/export-sef-admin/ExportSEFAdmin')));
const FormCreateSEF = Loadable(lazy(() => import('../views/create-sef/FormCreateSEF')));
const SignPDFTemplate = Loadable(lazy(() => import('../views/create-sef/pdf/SignPDFTemplate')));
const EditBillingInfo = Loadable(lazy(() => import('../views/edit-bill-info/EditBillingInfo')));
const Providers = Loadable(lazy(() => import('../views/providers/Providers')));
const UploadReport = Loadable(lazy(() => import('../views/upload-report/UploadReport')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/dashboard/create-sef',
                '/dashboard/view-sef',
                '/dashboard/export-sef',
                '/dashboard/export-sef-admin',
                '/dashboard/billed-sef',
                '/dashboard/billed-sef-logs',
                '/dashboard/form-create-sef',
                '/dashboard/sign-pdf',
                '/dashboard/view-logs',
                '/dashboard/edit-bill-info',
                '/dashboard/providers',
                '/dashboard/upload-report',

                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',

                '/sample-page'
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />
                        <Route path="/dashboard/create-sef" component={DashboardCreate} />
                        <Route path="/dashboard/view-sef" component={DashboardView} />
                        <Route path="/dashboard/export-sef" component={DashboardExport} />
                        <Route path="/dashboard/export-sef-admin" component={DashboardExportAdmin} />
                        <Route path="/dashboard/billed-sef" component={BilledSEF} />
                        <Route path="/dashboard/billed-sef-logs" component={BilledSEFLogs} />
                        <Route path="/dashboard/view-logs" component={ViewLogs} />

                        <Route path="/dashboard/providers" component={Providers} />

                        <Route path="/dashboard/edit-bill-info" component={EditBillingInfo} />

                        <Route path="/dashboard/form-create-sef" component={FormCreateSEF} />
                        <Route path="/dashboard/sign-pdf" component={SignPDFTemplate} />

                        <Route path="/dashboard/upload-report" component={UploadReport} />
                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
