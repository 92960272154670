import axios from 'axios';
import { LOGOUT } from './../store/actions';

import configData from '../config';
export const isTokenValid = (account, dispatcher) => {
    console.log(account.token);
    // .post(configData.API_SERVER + 'users/logout', { token: `${account.token}` }, { headers: { Authorization: `${account.token}` } })

    axios
        .get(configData.API_SERVER +'/api/auth/check', {
            headers: {
                Authorization: `${account.token}` // Assuming userToken is your JWT
            }
        })
        .then((response) => {
            console.log('Token is still valid:', response.data);
        })
        .catch((error) => {
            console.log('Token validation error:', error.response.data);
            dispatcher({ type: LOGOUT });
        });
};

export const isAdmin = (account) => {
    console.log(account.user.user_type);
    return account.user.user_type === 2 ? true : false;
};
export const isSuperAdmin = (account) => {
    console.log(account.user.user_type);
    return account.user.user_type === 1 ? true : false;
};
