import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
// import Logo from './../../../ui-component/Logo';
import logo from '../../../assets/images/coxphitlogo.png'; // Import the image

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <div style={{ textAlign: '-webkit-center', width: '100%' }}>
            <ButtonBase disableRipple component={Link} to={config.defaultPath}>
                <img src={logo} alt="CoxPHIT Logo" style={{ width: '100%', maxWidth: '150px' }} />
            </ButtonBase>
        </div>
    );
};

export default LogoSection;
