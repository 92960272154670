// action - account reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';

// Youth value
export const SET_YOUTH_VALUE = 'SET_YOUTH_VALUE';
export const SET_BILLING_INFORMATION = 'SET_BILLING_INFORMATION';



// Provider Data
export const SET_PROVIDER_DATA = 'SET_PROVIDER_DATA';

export const setYouth = (youthValue) => ({
    type: SET_YOUTH_VALUE,
    payload: youthValue,
});

export const setBillingInformation = (billInformation) => ({
    type: SET_BILLING_INFORMATION,
    payload: billInformation,
});


export const setProvider = (providerData) => ({
    type: SET_PROVIDER_DATA,
    payload: providerData,
});